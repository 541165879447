import WasmController from "react-lib/frameworks/WasmController";
import { MONTH_OPTION } from "react-lib/apps/HISV3/CLM/CardClaimTaxDocument";
// APIs
// BIL
import BeforePaymentCheckAPI from "issara-sdk/apis/BeforePaymentCheck_apps_BIL";
import ReceiptSerializer from "issara-sdk/types/ReceiptSerializer_apps_BIL";
import InvoiceList from "issara-sdk/apis/InvoiceList_apps_BIL";
import ReceiptByItemList from "issara-sdk/apis/ReceiptByItemList_apps_BIL";
import PrintReceiptByItemView from "issara-sdk/apis/PrintReceiptByItemView_apps_BIL";
import WalletPatientSummaryView from "issara-sdk/apis/WalletPatientSummaryView_apps_BIL";
import WalletPatientGetBalance from "issara-sdk/apis/WalletPatientGetBalance_apps_BIL";
import NewReportMedicalFeeDetailView from "issara-sdk/apis/NewReportMedicalFeeDetailView_apps_BIL";
import PrintReceipt from "issara-sdk/apis/PrintReceipt_apps_BIL";
import DoctorDetail from "issara-sdk/apis/DoctorDetail_core";
// ADM
import AdmitOrderRoomItemDetail from "issara-sdk/apis/AdmitOrderRoomItemDetail_apps_ADM";

// Interface
import { GetInvoiceItemList, GetMasterDataPayMethod } from "../BILInterface";

// Common
import getPdfMake from "react-lib/appcon/common/pdfMake";

// Form
import FormReceipt from "react-lib/apps/HISV3/BIL/FormReceipt";
import FormSapiensReceipt from "react-lib/apps/HISV3/BIL/FormSapiensReceipt";
import FormReceiptCoverageDocument from "../FormReceiptCoverageDocument";

// Utils
import * as CAgent from "react-lib/apps/common/CAgent";
import DepositView from "issara-sdk/apis/DepositView_apps_BIL";
import moment from "moment";
import CONFIG from "config/config";
import { combinePdfFiles } from "react-lib/apps/HISV3/common/CommonInterface";
import { formatComma } from "react-lib/utils/tsUtils";

export type State = {
  // CommonInterface
  bilReceiptCodeDetail?: {
    code: string;
    BIL_RECEIPT_SYSTEM_PREFIX: string;
    BIL_RECEIPT_PREFIX_SIZE: number;
    BIL_RECEIPT_RUNNING_SIZE: number;
  };
  selectedEncounter?: any;
  errorMessage?: any;
  selectedAdmitOrderRoomItem?: any;
  selectedPatient?: any;
  invoiceItemByModes?: any;
  invoiceItemByOrders?: any;
  invoiceItemByItems?: any;
  invoiceHistory?: any;
  goToMenu?: string;
  // Sequence
  BillPaymentSequence?: {
    sequenceIndex: string | null;
    openCardPaying?: boolean;
    sumAmount?: any;
    cashAmountText?: string;
    cashDepositText?: string;
    oweText?: string;
    cannotPay?: boolean;
    items?: any[];
    payments?: Payment[];
    rawOweText?: string;
    transferText?: string;
    cardText?: string;
    chequeText?: string;
    couponText?: string;
    installmentText?: string;
    depositText?: string;
    depositTotal?: string;
    totalText?: string;
    rawTotalText?: string;
    roundOffText?: string;
    masterAccountList?: any[];
    masterEDCList?: any[];
    receiptTargetList?: any[];
    requireApprovalCodeCheck?: {
      require_approval_code: boolean;
    };
    edcTransactionAmount?: {
      total_amount: number;
    };
    requireInterfaceClaimCodeCheck?: {
      require_interface_claim_code: boolean;
    };
    paymentMethodList?: {
      type: number | string;
      paymentNo: string | string[];
      paymentTarget: number;
      value: string;
      couponMessage?: "success" | "error";
    }[];
    autoEDC?: Partial<{
      check: boolean;
      approvalType: string;
      traceId: string;
      approvalCode: string;
    }>;
    payErrorMessage?: any;
    invoiceData?: any;
    doctorName?: any;
  } | null;
  BillingDepositSequence?: any;
  masterOptions?: any;
};

export const StateInitial: State = {
  BillPaymentSequence: null,
};

export type Event =
  | { message: "RunSequence"; params: {} }
  | { message: "SelectEncounter"; params: any }
  | { message: "GetMasterData"; params: {} };

export type Data = {
  division?: number;
  device?: number;
};

export const DataInitial = {};

type Payment = {
  type: number;
  payment_no: string;
  payment_target: number;
  value: number;
};

type Handler = (controller: WasmController<State, Event, Data>, params?: any) => any;

// Var
// var storedApprovalCode: string = "";

export const BeforePaymentCheck: Handler = async (controller, params) => {
  const state = controller.getState();
  if (!state.BillPaymentSequence) return;

  // Master data
  await controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: [
        ["doctor", {}],
        ["prenameTh", {}],
        ["prenameEn", {}],
      ],
    },
  });

  if (params.action === "invoice") {
    const result = await InvoiceList.create({
      data: {
        items: params.idList,
        patient: state.selectedPatient.id,
        type: "FULL_PAYMENT",
      },
      extra: {
        device: controller.data.device,
        division: controller.data.division,
      },
      apiToken: controller.apiToken,
    });

    if (result[0]) {
      controller.setState({
        invoiceHistory: true,
        BillPaymentSequence: {
          ...state.BillPaymentSequence,
          invoiceData: result[0],
        },
        goToMenu: params.goToMenu,
      });
    } else {
      controller.setState({
        errorMessage: {
          ...state.errorMessage,
          [params.card]: result[1],
        },
      });
    }

    return;
  }

  if (!params.invoiceData?.items) {
    return;
  }

  // ผู้ป่วยชำระราย item เปลี่ยนเป็น ผลรวมของ pay ที่ user กรอกมา
  let items: any[] = params.invoiceData.items || [];
  const isOrderItem = params.invoiceData.orderBy === "Item";
  const byItems: any[] = params.invoiceItemByItems || [];

  if (isOrderItem) {
    items = items.map((item) => ({
      ...item,
      price: item.pay,
      payable: item.pay,
    }));
  } else {
    // คำนวณค่าใช้จ่ายทั้งหมดโดยนำมาจาก pay ที่ by-items
    items = items.map((item) => {
      const sum = item.id_list.reduce(
        (result: number, id: number) =>
          result + Number(byItems.find((item) => item.id === id)?.pay || 0),
        0
      );
      return {
        ...item,
        price__sum: sum,
        payable__sum: sum,
      };
    });
  }

  const sumInitial = {
    price: 0,
    reimbursable: 0,
    cashonly: 0,
    discount: 0,
    payable: 0,
    send_claim: 0,
    absorb: 0,
    patient_claim: 0,
    non_claimable: 0,
  };

  const sum = items.reduce((acc: any, item: any) => {
    if (item.status__name === "BILLED" && !item.payment_ready) return acc;
    for (const key of Object.keys(sumInitial)) {
      acc[key] += Number.parseFloat(item[`${key}${isOrderItem ? "" : "__sum"}`]) || 0;
    }
    return acc;
  }, sumInitial);

  const check = await BeforePaymentCheckAPI.post({
    data: {
      items: params.invoiceData.ids,
    },
    apiToken: controller.apiToken,
    extra: {
      device: controller.data.device,
      division: controller.data.division,
    },
  });

  if (check[0]?.status === "error") {
    controller.setState({
      errorMessage: { ...state.errorMessage, [params.card]: check[0]?.message },
    });
  }

  if (check[0]?.status === "success" || params.allowKTB) {
    const oweText = sumInitial.payable;
    const roundOff = getDecimal(oweText);

    const [walletRes, walletErr, walletNet] = await WalletPatientGetBalance.get({
      params: {
        patient: state.selectedEncounter?.patient_id || state.selectedPatient?.id,
      },
      apiToken: controller.apiToken,
      extra: { division: controller.data.division },
    });

    // controller.setState({
    //   BillPaymentSequence: {
    //     ...state.BillPaymentSequence,
    //     sequenceIndex: "PaymentCalculate",
    //     sumAmount: {
    //       ...sum,
    //       payable: sum.payable - roundOff,
    //     },
    //     openCardPaying: true,
    //     cashAmountText: "",
    //     cashDepositText: "",
    //     oweText: (oweText - roundOff).toFixed(2),
    //     roundOffText: roundOff.toFixed(2),
    //     cannotPay: true,
    //     items: params.invoiceData.ids,
    //     rawOweText: (oweText - roundOff).toFixed(2),
    //     paymentMethodList: [
    //       { type: "1", paymentNo: "", paymentTarget: 0, value: "0.00" },
    //       { type: "6", paymentNo: "", paymentTarget: 0, value: "0.00" },
    //     ],
    //     autoEDC: {
    //       check: true,
    //       approvalType: "SELF",
    //     },
    //     transferText: "0.00",
    //     cardText: "0.00",
    //     chequeText: "0.00",
    //     couponText: "0.00",
    //     installmentText: "0.00",
    //     depositText: "0.00",
    //     totalText: "0.00",
    //     rawTotalText: "0.00",
    //     depositTotal: walletRes?.balance || "0.00",
    //   },
    // });

    const paymentData = {
      sequenceIndex: "PaymentCalculate",
      sumAmount: {
        ...sum,
        payable: sum.payable - roundOff,
      },
      openCardPaying: true,
      cashAmountText: "",
      cashDepositText: "",
      oweText: (oweText - roundOff).toFixed(2),
      roundOffText: roundOff.toFixed(2),
      cannotPay: true,
      items: params.invoiceData.ids,
      rawOweText: (oweText - roundOff).toFixed(2),
      paymentMethodList: [
        { type: "1", paymentNo: "", paymentTarget: 0, value: "0.00" },
        { type: "6", paymentNo: "", paymentTarget: 0, value: "0.00" },
      ],
      autoEDC: {
        check: true,
        approvalType: "SELF",
      },
      transferText: "0.00",
      cardText: "0.00",
      chequeText: "0.00",
      couponText: "0.00",
      installmentText: "0.00",
      depositText: "0.00",
      totalText: "0.00",
      rawTotalText: "0.00",
      depositTotal: walletRes?.balance || "0.00",
      doctorName: state.selectedEncounter?.doctor || "",
    };

    GetMasterDataPayMethod(controller as any, {
      ...params,
      idList: params.invoiceData.ids,
      paymentData: paymentData,
    });
  } else if (check[0]?.message === "รายการรับชำระย้อนหลัง ต้องขอเลข approval code ในระบบ KTB") {
    controller.setState({
      errorMessage: {
        ...state.errorMessage,
        [`${params.card}_KTB`]: check[0]?.message,
      },
    });
  }
};

export const PaymentCalculate: Handler = async (controller, params) => {
  const state = controller.getState();
  if (!state.BillPaymentSequence) return;
  if (params.action === "close") return controller.setState({ BillPaymentSequence: null });

  if (params.action !== "pay") {
    UpdateTextMoneyValue(controller, params);
  } else if (params.action === "pay") {
    return controller.setState(
      {
        BillPaymentSequence: {
          ...state.BillPaymentSequence,
          sequenceIndex: "CreateReceipt",
        },
      },
      () => controller.handleEvent({ message: "RunSequence", params: params })
    );
  }
};

const UpdateTextMoneyValue: Handler = (controller, params) => {
  const state = controller.getState();

  if (!state.BillPaymentSequence) {
    return;
  }

  const sumInitial = {
    transferText: 0,
    cardText: 0,
    chequeText: 0,
    couponText: 0,
    installmentText: 0,
    depositText: 0,
  };

  const type: any = {
    2: "transferText",
    3: "cardText",
    4: "chequeText",
    5: "couponText",
    6: "depositText",
    7: "installmentText",
  };

  const cashAmountText = state.BillPaymentSequence.cashAmountText;
  const cashDepositText = state.BillPaymentSequence.cashDepositText;
  const rawOweText = Number(state.BillPaymentSequence.rawOweText);
  // #const roundOffText = Number(state.BillPaymentSequence.roundOffText);
  const payments = state?.BillPaymentSequence?.paymentMethodList || [];
  const sum = payments.reduce((acc, item) => {
    // if (item.type === "1") {
    //   (acc as any)[type[item.type]] += Number.parseFloat(item.value) || 0;
    // }
    (acc as any)[type[item.type]] += Number.parseFloat(item.value) || 0;

    return acc;
  }, sumInitial);

  const total =
    sum.transferText +
    sum.cardText +
    sum.chequeText +
    sum.couponText +
    // sum.depositText +
    sum.installmentText +
    (Number(cashAmountText) || 0) +
    (Number(cashDepositText) || 0);

  const billPayment = {
    ...state.BillPaymentSequence,
    transferText: sum.transferText.toFixed(2),
    cardText: sum.cardText.toFixed(2),
    chequeText: sum.chequeText.toFixed(2),
    couponText: sum.couponText.toFixed(2),
    installmentText: sum.installmentText.toFixed(2),
    totalText: rawOweText > total ? total.toFixed(2) : rawOweText.toFixed(2),
    rawTotalText: total.toFixed(2),
    oweText: rawOweText > total ? (rawOweText - total).toFixed(2) : "0.00",
    sequenceIndex: "PaymentCalculate",
    cannotPay: rawOweText > total,
  };

  controller.setState({ BillPaymentSequence: billPayment });

  if (params.isUpdateCashAmount) {
    payments[0].paymentNo = `${Number(cashAmountText).toFixed(2)} / ${
      Number(cashAmountText) > rawOweText
        ? (Number(cashAmountText) - rawOweText).toFixed(2)
        : "0.00"
    }`;
    payments[0].value =
      Number(cashAmountText) > rawOweText ? `${rawOweText}` : cashAmountText || "";

    controller.setState({
      BillPaymentSequence: { ...billPayment, paymentMethodList: payments },
    });
  } else if (params.isUpdateCashDeposit) {
    payments[1].paymentNo = `${Number(cashDepositText).toFixed(2)} / ${
      Number(cashDepositText) > rawOweText
        ? (Number(cashDepositText) - rawOweText).toFixed(2)
        : "0.00"
    }`;
    payments[1].value =
      Number(cashDepositText) > rawOweText ? `${rawOweText}` : cashDepositText || "0";

    controller.setState({
      BillPaymentSequence: { ...billPayment, paymentMethodList: payments },
    });
  }
};

export const CreateReceipt: Handler = async (controller, params) => {
  const state = controller.getState();
  if (!state.BillPaymentSequence) return;
  params.loading?.(true);

  const bill = state.BillPaymentSequence;
  const payments = [...(bill.paymentMethodList || [])].map((item) => ({
    type: item.type,
    payment_no:
      item.type === 3
        ? `${item.paymentNo?.[0]}${item.paymentNo?.[1]}${item.paymentNo?.[2]}${item.paymentNo?.[3]}`
        : item.paymentNo,
    payment_target: item.paymentTarget,
    value: item.value,
  }));

  if (payments[0].type === "1" && Number(payments[0].value) === 0) {
    payments.shift();
  }

  // check payments deposit only
  let receiptType =
    payments.length === 1 && payments?.[0]?.type === "6"
      ? "invoice"
      : payments.length > 1 &&
        payments.filter((item: any) => item.type === "6" && item.payment_no !== "").length === 1
      ? "invoice&receipt"
      : params.isDeposit
      ? "deposit"
      : "receipt";

  const idList = await getInvoiceIdList(controller, params);

  // ยังไม่พร้อมใช้งาน comment ไว้ก่อนค่อยมาเปิด function นี้
  // if (
  //   bill.autoEDC?.check &&
  //   bill.requireApprovalCodeCheck?.require_approval_code &&
  //   !params.confirmData
  // ) {
  //   return HandleKtbedcApprove(controller, params);
  // }

  let data = {
    payments,
    items: idList,
    patient: state.selectedPatient.id,
    patient_name_lang: params.patientNameLang,
    price: bill.rawOweText,
    invoice: params.invoice,
    code: "",
    round_off: bill.roundOffText,
    reference_code: bill.autoEDC?.traceId || "",
    approval_code: bill.autoEDC?.approvalCode || "",
    terminal_id: "",
    merchant_id: "",
    tx_date: null,
    tx_time: null,
    edc_amount: bill.edcTransactionAmount?.total_amount,
    require_approval_code: bill.requireApprovalCodeCheck?.require_approval_code ?? false,
    receipt_code: state.bilReceiptCodeDetail?.code || "",
    wallet_type: params.walletType,
    ...(CONFIG?.BIL_RECEIPT === "sapiens" && {
      only_data: true,
    }),
    // receipt_code: "01-0000003",
    // receipt_target: null,
    // nhso_token_key: root.nhso_token_key,
    // nhso_patient_type: root.nhso_patient_type,
    // nhso_smartcard_data: root.nhso_smartcard_data,
  } as ReceiptSerializer;

  // เมื่อเป็นสิทธิชำระเงินและ Confirm เมื่อเรียก CAgent
  if (params.confirmData) {
    const confirm = params.confirmData;
    data = {
      ...data,
      reference_code: confirm.traceId || "",
      approval_code: confirm.approvalCode || "",
      terminal_id: confirm.terminalId || "",
      merchant_id: confirm.merchantId || "",
      tx_date: confirm.date || null,
      tx_time: confirm.time || null,
      edc_amount: bill.edcTransactionAmount?.total_amount,
    };
  }

  const api = params.isDeposit ? DepositView.create : ReceiptByItemList.post;

  const result = await api({
    data,
    extra: {
      device: controller.data.device,
      division: controller.data.division,
    },
    apiToken: controller.apiToken,
  });

  console.log(result[0] ? result[0] : result[1]);

  if (result[1]) {
    params.loading?.(false);

    controller.setState({
      BillPaymentSequence: {
        ...state.BillPaymentSequence,
        payErrorMessage: result[1],
        sequenceIndex: "PaymentCalculate",
      },
    });
  } else {
    // TODO * เพื่อแก้ปัญหา get invoiceitem แล้ว response ไม่เปลี่ยนแปลง
    await new Promise((resolve) => setTimeout(() => resolve("!!!DONE"), 500));

    if (params.isDeposit) {
      await WalletPatientSummaryView.get({
        params: {
          wallet_type: 1,
          patient: state.selectedEncounter?.patient_id,
        },
        apiToken: controller.apiToken,
        extra: { division: controller.data.division },
      });

      controller.setState({
        BillingDepositSequence: {
          ...state.BillingDepositSequence,
          billDepositDetail: {
            ...state.BillingDepositSequence?.billDepositDetail,
            selectDeposit: {
              id: "",
              code: null,
              receipt_no: null,
              walletValue: "",
              init_balance: "0.00",
              patient_paid: "0.00",
              payment: "0.00",
              balance: "0.00",
            },
            sumWallet: {
              sumTotal: 0.0,
              sumPayable: 0.0,
              sumAmount: 0.0,
              sumRemian: 0.0,
            },
          },
        },
      });

      controller.handleEvent({
        message: "RunSequence",
        params: { sequence: "BillingDeposit", action: "getHistory" },
      });
    }

    const [doctorDetailRes, doctorDetailErr, doctorDetailNet] = await DoctorDetail.retrieve({
      apiToken: controller.apiToken,
      pk: state?.BillPaymentSequence?.doctorName,
    });

    const prenameTh =
        (state.masterOptions?.prenameTh || []).find(
          (item: any) => item.value === doctorDetailRes.pre_name
        )?.text || null;

      const prenameEn =
        (state.masterOptions?.prenameEn || []).find(
          (item: any) => item.value === doctorDetailRes.pre_name_en
        )?.text || null;

      let doctorName =
        doctorDetailRes?.pre_name_en &&
        doctorDetailRes?.first_name_en &&
        doctorDetailRes?.last_name_en
          ? `${doctorDetailRes?.full_name}  (${prenameEn} ${doctorDetailRes?.first_name_en} ${doctorDetailRes?.last_name_en})`
          : doctorDetailRes?.full_name;

    const printReceiptData = {
      ...result?.[0],
      approval_code: data.approval_code,
      reference_code: data.reference_code,
      receiptType: receiptType,
      isDeposit: params.isDeposit,
      doctorName: doctorName,
    };

    // console.log("Yeti Print Receipt Data Line 590: ", printReceiptData);

    if (!params.simpleReceipt) {
      await HandlePrintReceipt(controller, printReceiptData); // issue 67700
    }

    params.loading?.(false);

    params.callback?.({ status: "success", message: result?.[0]?.go_to });

    // #59883 ไม่แสดง pop-up ว่าบันทึกสำเร็จ
    controller.setState({ BillPaymentSequence: { openCardPaying: true } });

    // Invoice
    controller.handleEvent({ message: "GetInvoiceItemByMode" } as any);
    controller.handleEvent({ message: "GetInvoiceItemByOrder" } as any);
    controller.handleEvent({ message: "GetInvoiceItemByItem" } as any);

    // Bill Pending
    controller.handleEvent({
      message: "GetBillPendingDetail",
      params: {},
    } as any);

    // Receipt Code
    controller.handleEvent({
      message: "HandleGetReceiptCode",
      params: {},
    } as any);

    if (state.selectedAdmitOrderRoomItem?.id) {
      const admitRes = await AdmitOrderRoomItemDetail.retrieve({
        pk: state.selectedAdmitOrderRoomItem?.id,
        apiToken: controller.apiToken,
      });

      controller.setState({
        selectedAdmitOrderRoomItem: admitRes[0] || null,
      });
    }
  }
};

const getInvoiceIdList: Handler = async (controller, params) => {
  const invoiceItems = params.showFilter
    ? await GetInvoiceItemList(controller, params)
    : [{ items: params.invoiceItemByItems }];

  const byItems: any[] = invoiceItems[0]?.items || [];
  const items: any[] = params.invoiceData?.items || [];
  const ids: number[] = params.invoiceData?.ids || [];

  return params.invoiceData?.orderBy === "Item"
    ? items.map((item) => ({
        id: item.id,
        pay: item.pay,
      }))
    : ids.map((id) => {
        const item = byItems.find((item) => item.id === id);

        return {
          id: item.id,
          pay: item.pay,
        };
      });
};

export const HandlePrintReceipt: Handler = async (controller, params) => {
  // let historyApprovalCode: string = "";
  const api =
    CONFIG?.BIL_RECEIPT === "sapiens" ? PrintReceipt.retrieve : PrintReceiptByItemView.get;
  // const printReceipt = await PrintReceipt.retrieve({
  //   pk: item?.id,
  //   params: {
  //     ignore_print_copy_flag: true,
  //   },
  //   apiToken: controller.apiToken,
  //   extra: {
  //     device: controller.data.device,
  //     division: controller.data.division,
  //   },
  // });

  // Save Approval for History Receipt Print
  // if (params?.approval_code) {
  //   historyApprovalCode = params.approval_code;
  //   storedApprovalCode = historyApprovalCode;
  // } else {
  //   params.approval_code = storedApprovalCode || historyApprovalCode;
  // }

  const result = await api({
    apiToken: controller.apiToken,
    pk: params.id,
    params: {
      ...(CONFIG?.BIL_RECEIPT === "sapiens" && {
        only_data: true,
      }),
      ...(params?.note && { note: params?.note }),
    },
    extra: {
      device: controller.data.device,
      division: controller.data.division,
    },
  });

  const [receipt, receiptCoverageDoc] = await Promise.all([
    createPDFReceipt(
      controller,
      {
        ...result[0]?.receipt,
        ...(params.isDeposit && {
          fields: [
            {
              bill_mode__name: "เงินมัดจำ (Deposit)",
              claimable__sum: "",
              nonclaimable__sum: result[0]?.receipt?.price,
              discount__sum: "",
              net__sum: result[0]?.receipt?.price,
            },
          ],
          nonclaimable_sum: result[0]?.receipt?.price,
          net_sum: result[0]?.receipt?.price,
        }),
      },
      params
    ),
    createPDFReceiptCoverageDocList(result[0]?.receipt_coverage_document_list, params),
  ]);

  // console.log("Yeti Handle Print Receipt Params: ", params);
  // console.log("Yeti Handle Print Receipt Result: ", result);

  receipt?.open();

  for (const doc of receiptCoverageDoc || []) {
    doc.open();
  }
};

const HandleKtbedcApprove: Handler = (controller, params) => {
  const state = controller.getState();
  const patient = state.selectedPatient || {};
  const bill = state.BillPaymentSequence;

  if (patient.citizen_type == "T") {
    patient.personal_id = patient.citizen_no;
  } else if (patient.citizen_type == "F") {
    patient.personal_id = patient.reimburse_foreign_code;
  } else if (patient.citizen_type == "A") {
    patient.personal_id = patient.reimburse_foreign_code;
  }

  CAgent.ktbedcApprove(
    patient.personal_id?.replace(/\D/g, ""),
    bill?.edcTransactionAmount?.total_amount,
    bill?.autoEDC?.approvalType
  )
    .then(function (result) {
      if (result.approvalCode) {
        params.callback?.({ status: "confirm", message: result });
        params.loading?.(false);
      } else {
        params.callback?.({ status: "error_payment", message: result });
        params.loading?.(false);
      }
    })
    .catch(function (result) {
      params.loading?.(false);

      controller.setState({
        BillPaymentSequence: {
          ...state.BillPaymentSequence,
          payErrorMessage: result,
          sequenceIndex: "PaymentCalculate",
        },
      });
    });
};

/* ------------------------------------------------------ */

/*                          Utils                         */

/* ------------------------------------------------------ */
const getDecimal = (number: any) => {
  var decimalPlace = number - parseInt(number);
  return parseFloat(decimalPlace.toFixed(2));
};

export const createPDFReceipt = async (controller: any, receipt: any, item: any) => {
  const state = controller.getState();
  if (!receipt || Object.keys(receipt || {}).length === 0) {
    return null;
  }

  let docDef: any = { content: [] };

  const fields: any[] = receipt?.fields || [];

  // sapiens
  let totalNonClaimable: Number = 0;
  let totalSelfReimbursement: Number = 0;
  let totalAmount: Number = 0;

  const filterPayment: Record<string, string> = {
    is_cash: "เงินสด",
    is_credit_card: "บัตรเครดิต",
    is_transfer: "QR Code.",
  };

  const requiredField = ["is_cash", "is_credit_card", "is_transfer"];

  let paymentType = "";

  const checkPaymentType = (data: any) => {
    return paymentType === "" ? paymentType : `${paymentType},`;
  };

  requiredField.forEach((item: string) => {
    const receiptData = receipt?.[item];

    if (item === "is_cash" && receiptData) {
      paymentType = `${checkPaymentType(paymentType)} ${filterPayment[item]}`;
    }
    if (item === "is_credit_card" && receiptData) {
      paymentType = `${checkPaymentType(paymentType)} ${filterPayment[item]} (${
        receipt?.bank_name
      })`;
    }
    if (item === "is_transfer" && receiptData) {
      paymentType = `${checkPaymentType(paymentType)} ${filterPayment[item]} (${
        receipt?.bank_name
      })`;
    }
  });

  fields.forEach((item: any) => {
    let selfReimbursement =
      item?.self_reimbursement === "" ? 0 : item?.self_reimbursement?.replaceAll(",", "");

    totalNonClaimable = Number(totalNonClaimable) + Number(item.non_claimable?.replaceAll(",", ""));
    totalSelfReimbursement = Number(totalSelfReimbursement) + Number(selfReimbursement);

    totalAmount =
      Number(totalAmount) +
      (Number(item.non_claimable?.replaceAll(",", "")) - Number(selfReimbursement));
  });

  let monthText = String(MONTH_OPTION.find((month: any) => month?.text === receipt?.month)?.value);

  let walletReceiptCodes = "";
  (receipt?.wallet_receipt_codes || []).map((items: any, index: any) => {
    walletReceiptCodes = index === 0 ? items : `${walletReceiptCodes}, ${items}`;
  });

  let paymentTypeSplit = receipt?.payment_type?.split("\n");
  let paymentTypeText = "";
  (paymentTypeSplit || [])
    .filter((items: any) => items !== "")
    ?.map((items: any, index: number) => {
      let typeName = items.split("(")?.[0];
      let typeValue = items.split("(")?.[1]?.split(")")?.[0];
      let typeText = `${typeName}(${formatComma(typeValue?.replace(",", ""))})`;

      if (item?.receiptType !== "deposit") {
        if ((index === 0 && items.includes("เงินสด")) || (index === 1 && items.includes("รับ"))) {
          paymentTypeText = index === 0 ? items : `${paymentTypeText}, ${items}`;
        } else {
          paymentTypeText = index === 0 ? typeText : `${paymentTypeText}, ${typeText}`;
        }
      } else {
        if (index === 0 && items.includes("เงินสด")) {
          paymentTypeText = typeText;
        } else if (!items.includes("รับ")) {
          paymentTypeText = index === 0 ? typeText : `${paymentTypeText}, ${typeText}`;
        }
      }
    });

  let headerSize = 282;
  let footerSize = 227;
  let patientNameEN =
    state.selectedPatient?.full_name_en !== "" && `(${state.selectedPatient?.full_name_en})`;
  let doctorName = item.doctorName || receipt?.doctor;

  if (`${receipt.name}  ${patientNameEN || ""}`.length >= 60) {
    headerSize = headerSize + 12;
    footerSize = footerSize - 10;
  }
  if ((doctorName || "").length >= 60) {
    headerSize = headerSize + 12;
    footerSize = footerSize - 10;
  }

  const data = {
    ...receipt,
    fields,
    sum_non_claimable: fields.reduce(
      (result, item) => Number(item?.non_claimable?.replace(",", "")) + result,
      0
    ),
    sum_self_reimbursement: fields.reduce(
      (result, item) => Number(item?.self_reimbursement?.replace(",", "")) + result,
      0
    ),

    // sapiens
    // code: item?.code?.replace("CH", "")?.replace("-", "/"),
    created: `${receipt?.day}/${monthText.length < 2 ? `0${monthText}` : monthText}/${
      receipt?.year
    }`,
    // createdTime: `${moment(item?.created).format("HH:mm")}`,
    // hn: receipt.hn?.replace("-", ""),
    // paymentType: paymentType,
    // totalNonClaimable: totalNonClaimable,
    // totalSelfReimbursement: totalSelfReimbursement,
    // totalAmount: totalAmount,
    patientNameEN: patientNameEN,
    // ...(!receipt?.nameEN && {
    //   nameEN: receipt?.name,
    // }),
    payment_type: paymentTypeText,
    isWatermark: item?.isWatermark || false,
    wallet_receipt_codes: walletReceiptCodes,
    encounter: receipt?.an,
    doctor: doctorName,
    visit_date: receipt?.visit_date,
    coverage: receipt?.coverage,
    discharge_date: receipt?.discharge_date,
    division: receipt?.division,
    pageMargins: [0, headerSize, 0, footerSize],
  };

  if (CONFIG?.BIL_RECEIPT === "sapiens") {
    if (item?.receiptType === "invoice&receipt") {
      const formsPromises: any[] = [
        FormSapiensReceipt({ ...data, receiptType: "invoice" }),
        FormSapiensReceipt({ ...data, receiptType: "receipt" }),
      ];

      const forms = await Promise.all(formsPromises);

      const bloburl = await combinePdfFiles(forms);

      globalThis.open(bloburl);
    } else {
      docDef = await FormSapiensReceipt({ ...data, receiptType: item?.receiptType || "receipt" });

      return (await getPdfMake(true)).createPdf(docDef);
    }
  } else if (CONFIG?.BIL_RECEIPT === "cudent" || item?.printType === "cudent") {
    docDef = FormReceipt({ ...data });

    return (await getPdfMake()).createPdf(docDef);
  } else {
    // cnmi ใบรายละเอียด
    // const report = await NewReportMedicalFeeDetailView.get({
    //   params: {
    //     receipt: item?.id,
    //     patient_name_lang: "TH",
    //   },
    //   apiToken: controller.apiToken,
    // });

    // controller.setState({
    //   billReportPrint: { open: true, task_id: report[0]?.task_id || "" },
    // });

    // cnmi ใบเสร็จ
    const printReceipt = await PrintReceipt.retrieve({
      pk: item?.id,
      params: {
        ignore_print_copy_flag: true,
      },
      apiToken: controller.apiToken,
      extra: {
        device: controller.data.device,
        division: controller.data.division,
      },
    });
  }
};

export const createPDFReceiptCoverageDocList = async (receipt: any[], params?: any) => {
  if (!receipt || !receipt?.length) {
    return null;
  }

  const pdfMake = await getPdfMake();

  const promiseArr = receipt.map((item) => {
    let docDef: any = { content: [] };

    const data = { ...item, params };

    // console.log("Yeti Data in createPDFReceiptCoverageDoc: ", data);

    docDef = FormReceiptCoverageDocument({ ...data });

    return pdfMake.createPdf(docDef);
  });

  return Promise.all(promiseArr);
};
