import React, { useEffect, useMemo, useState } from "react";

// UX
import CardVisaExtensionCertificateUX from "./CardVisaExtensionCertificateUX";

// Common

// Interface
import { State } from "./sequence/DoctorCertificate";

// Utils
// import moment from "moment";
// import { dateToStringWithoutTimeBE } from "react-lib/utils/dateUtils";
import { Dimmer, Loader, Button } from "semantic-ui-react";

// Props
type CardVisaExtensionCertificateProps = {
  // Function
  setProp: any;

  // Options
  masterOptions?: any;

  // Sequence
  runSequence?: any;
  DoctorCertificateSequence: State["DoctorCertificateSequence"];
};

// Const
// const defaultDate = dateToStringWithoutTimeBE(moment());

const TREATMENT_LIST = [
  "isDrug",
  "isPhysicalTherapy",
  "isXray",
  "isProcedure",
  "isPathology",
  "isSurgery",
  "isBandage",
  "isOther",
];

const CITIZEN_TYPE = [
  "T", // Thai
  "A", // Alien
  "N", // Non Identity
];

// Main
const CardVisaExtensionCertificate = (props: CardVisaExtensionCertificateProps) => {
  const getCitizenType = (type: string) => {
    if (type) {
      if (!CITIZEN_TYPE.includes(type)) {
        return props.DoctorCertificateSequence?.visaExtReport?.patientInfo?.citizen_passport;
      }
    }

    return "";
  };

  // Set State
  const [nationality, setNationality] = useState("");
  const [passport, setPassport] = useState(
    getCitizenType(props.DoctorCertificateSequence?.visaExtReport?.patientInfo?.citizen_type)
  );
  const [doctor, setDoctor] = useState("");

  // Call Sequence ( VisaExt Certificate )
  useEffect(() => {
    props.runSequence({
      sequence: "DoctorCertificate",
      action: "FETCH_CERTIFICATE",
      fetchType: "CardVisaExtensionCertificate",
    });
  }, []);

  // Start Value
  useMemo(() => {
    setNationality(props.DoctorCertificateSequence?.visaExtReport?.patientNationality);
    // setIdentification(props.DoctorCertificateSequence?.visaExtReport?.patientPassport);
    setDoctor(props.DoctorCertificateSequence?.visaExtReport?.doctorEncounter);
  }, [props.DoctorCertificateSequence, props.masterOptions]);

  const handleDisableRadio = (type: string) => {
    switch (type) {
      case "OPD":
        return {
          disableOpd: false,
          disableIpd: true,
        };
      case "IPD":
        return {
          disableOpd: true,
          disableIpd: false,
        };
      default:
        return {
          disableOpd: true,
          disableIpd: true,
        };
    }
  };

  const handleChangeText = (name: any, e: React.ChangeEvent<HTMLInputElement>) => {
    switch (name) {
      case "chiefComplaint":
      case "diagnosis":
      case "isProcedureNote":
      case "isSurgeryNote":
      case "isOtherNote":
        props.setProp(`DoctorCertificateSequence.visaExtReport.${name}`, e.target.value);
        break;
      case "patientPassport":
        setPassport(e.target.value);
        props.setProp(`DoctorCertificateSequence.visaExtReport.${name}`, e.target.value);
        break;
      default:
        console.warn(`Name Doesn't Exist!! Name: ${name} Value: ${e.target.value}`);
        break;
    }
  };

  const handleChangeDuration = (e: any, value: string) => {
    props.setProp(`DoctorCertificateSequence.visaExtReport.treatmentDuration`, value);
  };

  const handleGetCheckboxValue = (name: string) => {
    if (TREATMENT_LIST.includes(name)) {
      return props.DoctorCertificateSequence?.visaExtReport?.[name];
    } else {
      console.warn("Have no Option: ", name);
      return;
    }
  };

  const handleChangeCheckbox = (e: any, name: string, data: any) => {
    if (data.type === "checkbox") {
      let value = data.checked;
      props.setProp(`DoctorCertificateSequence.visaExtReport.${name}`, value);
    }
  };

  const handleChangeNationality = (e: any, { value }: { value: string }) => {
    props.setProp(`DoctorCertificateSequence.visaExtReport.patientNationality`, value);
    setNationality(value);
  };

  const handleChangeDoctor = (e: any, { value }: { value: string }) => {
    props.setProp(`DoctorCertificateSequence.visaExtReport.doctorEncounter`, value);
    setDoctor(value);
  };

  // Condition Close Button
  // const clearSelectedData = () => {
  //   // Set selectedReport = null
  //   props.setProp(`DoctorCertificateSequence.selectedReport`, null);

  //   // Re-Fetch
  //   props.runSequence({
  //     sequence: "DoctorCertificate",
  //     action: "CLEAR_REPORT",
  //     callReport: "CardVisaExtensionCertificate",
  //   });
  // };

  // const closeSelectedCheck = (reportSelected: any) => {
  //   if (reportSelected != null) {
  //     return (
  //       <Button
  //         color="red"
  //         style={{ width: "8em" }}
  //         onClick={clearSelectedData}
  //       >
  //         ปิด
  //       </Button>
  //     );
  //   } else {
  //     return null;
  //   }
  // };

  // Disable when select FiveForm
  const handleDisableForm = (selected: any, reportType?: any) => {
    if (selected == null) {
      return false;
    } else if (selected !== null && reportType === "ใบรับรองแพทย์สำหรับ Visa Extension") {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <CardVisaExtensionCertificateUX
        // Nationality
        nationalityOptions={props.masterOptions?.nationality || []}
        // nationalityValue={
        //   props.DoctorCertificateSequence?.visaExtReport?.patientInfo?.nationality || ""
        // }
        nationalityValue={nationality || ""}
        onHandleChangeNationality={handleChangeNationality}
        disableNationality={
          props.DoctorCertificateSequence?.visaExtReport?.patientInfo?.nationality ? true : false
        }
        //* Passport (ให้แสดง citizen_passport เท่านั้น [ใช้ citizen_type กำกับ])
        //! state.selectedPatient -> citizen_passport = citizen_no
        patientPassport={passport || ""}
        // Encounter Type
        opdRadio={
          props.DoctorCertificateSequence?.visaExtReport?.patientInfo?.encounter_type === "OPD"
        }
        ipdRadio={
          props.DoctorCertificateSequence?.visaExtReport?.patientInfo?.encounter_type === "IPD"
        }
        onHandleDisableRadio={handleDisableRadio(
          props.DoctorCertificateSequence?.visaExtReport?.patientInfo?.encounter_type
        )}
        // Cheif Complaint
        chiefComplaintValue={props.DoctorCertificateSequence?.visaExtReport?.chiefComplaint || ""}
        onHandleChangeText={handleChangeText}
        // Diagnosis Value
        diagnosisValue={props.DoctorCertificateSequence?.visaExtReport?.diagnosis || ""}
        // Doctor List
        doctorOptions={props.masterOptions?.doctor || []}
        doctorValue={doctor || ""}
        onHandleChangeDoctor={handleChangeDoctor}
        // Treatment Duration
        durationSelected={(value: string) =>
          props.DoctorCertificateSequence?.visaExtReport?.treatmentDuration === `${value}`
        }
        onHandleChangeDuration={handleChangeDuration}
        // Treatment Options
        onHandleGetCheckboxValue={handleGetCheckboxValue}
        onHandleChangeCheckbox={handleChangeCheckbox}
        // Treatment Note (Procedure, Surgery, Other)
        disableProcedureNote={
          props.DoctorCertificateSequence?.visaExtReport?.isProcedure ? false : true
        }
        disableSurgeryNote={
          props.DoctorCertificateSequence?.visaExtReport?.isSurgery ? false : true
        }
        disableOtherNote={props.DoctorCertificateSequence?.visaExtReport?.isOther ? false : true}
        // Close Button When Selected Report
        // onCloseSelected={closeSelectedCheck(
        //   null
        // )}
        // Disable Input
        // onHandleDisableForm={handleDisableForm(
        //   null,
        //   props.DoctorCertificateSequence?.selectedReport?.data?.reportType
        // )}
      />
    </>
  );
};

export default CardVisaExtensionCertificate;
